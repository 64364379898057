import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Product from "./components/Product";
import Header from "./components/Header";
import Preloader2 from "./components/Preloader2";
import { Helmet } from "react-helmet";

const Menu = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState(110);
  const [display, setDisplay] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [oneTime, setOneTime] = useState(true);
  const [preOrderState, setPreOrderState] = useState(true);

  useEffect(() => {
    document.querySelector("body").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getProductsByCategory(110);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/restaurants/6`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((res) => {
        let response = res.data;
        return response;
      })
      .then((response) => {
        setIsOpen(response.is_open);
        setPreOrderState(response.accept_pre_order);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [oneTime]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/restaurants/6/categories`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((res) => {
        let cat = res.data;
        return cat;
      })
      .then((cat) => {
        setCategories(cat);
        setDisplay(false);
      })
      .catch((err) => {
        console.log(err);
        setDisplay(false);
      });
  }, []);

  const getProductsByKeyword = (keyword) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/restaurants/6/search/${keyword}?description=false`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((res) => {
        let data = res.data;
        return data;
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductsByKeywordNoPreload = (categoryId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/restaurants/6/categories/${categoryId}/items`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((res) => {
        let data = res.data;
        return data;
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductsByCategory = (categoryId) => {
    setDisplay(true);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/restaurants/6/categories/${categoryId}/items`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((res) => {
        let data = res.data;
        return data;
      })
      .then((data) => {
        setProducts(data);
        setDisplay(false);
      })
      .catch((err) => {
        console.log(err);
        setDisplay(false);
      });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Menu Sushi Street</title>
        <meta
          name="description"
          content="Découvrez notre carte et vivez le vrai gout Asiatique avec Sushi Street. Les précommandes sont disponible pour livraison ou à emporter. "
        />
      </Helmet>
      <Header />
      <Preloader2 display={`${display === true ? "d-block" : "d-none"}`} />

      <div className="mad-content p-2">
        <div className="container">
          <div className="product-sort-section">
            <div className="mad-col">
              <nav className="mad-filter-wrap">
                <ul
                  id="portfolio-filter"
                  className="mad-filter justify-content-center"
                >
                  {Object.keys(categories).length > 0 &&
                    Object.entries(categories).map((category, i) => (
                      <li
                        className={`nav-item ${
                          activeTab === parseInt(category[1].cat_id)
                            ? "mad-active"
                            : ""
                        }`}
                        key={category[1].cat_id}
                        onClick={() => {
                          getProductsByCategory(category[1].cat_id);
                          setActiveTab(parseInt(category[1].cat_id));
                        }}
                      >
                        <a href="#">{category[1].category_name}</a>
                      </li>
                    ))}
                </ul>
              </nav>
            </div>
          </div>

          <div className="mad-widget search-box">
            <form className="one-line type-2 size-2">
              <input
                type="text"
                placeholder="Recherche de produits"
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    getProductsByKeyword(e.target.value);
                  }
                  if (e.target.value.length === 0) {
                    getProductsByKeywordNoPreload(31);
                  }
                  setSearchKeyword(e.target.value);
                }}
              />
              <span className="btn btn-style-3">
                <i className="material-icons">search</i>
              </span>
            </form>
          </div>
          <div
            data-isotope-layout="grid"
            data-isotope-filter="#portfolio-filter"
            className="mad-products item-col-4 mad-grid--isotope"
          >
            {Object.keys(products).length > 0 &&
              Object.entries(products).map((product, i) => (
                <Product
                  key={product[1].item_id}
                  product={product}
                  disabled={!isOpen}
                  preOrderState={preOrderState}
                />
              ))}
          </div>
          {Object.keys(products).length === 0 && searchKeyword.length > 0 && (
            <div className="text-center search-alert-box">
              <p className="search-alert-text">
                Aucun produit ne correspond à votre recherche
              </p>
              <div className="d-flex search-alert-img justify-content-center align-items-center">
                <img
                  src="images/page-not-found.svg"
                  loading="lazy"
                  alt="Page Not Found"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
