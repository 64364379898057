import React, { useState, Fragment } from 'react';
import ModalMenu from './ModalMenu';

const Product = ({ product, disabled, preOrderState }) => {
  let imgPath = 'https://food.powerbiz.fr/upload/' + product[1].photo;
  const [openModal, setOpenModal] = useState(false);

  const sendDataToParent = (st) => {
    setOpenModal(st);
  };

  return (
    <Fragment>
      <div className="mad-grid-item mad-category-burgers">
        <div className="mad-product">
          <figure className="mad-product-image">
            <a href="#">
              <img src={imgPath} alt={product[1].item_name} loading="lazy" />
            </a>
          </figure>

          <div className="mad-product-description">
            <h4 className="mad-product-title">
              <a href="#" className="mad-link">
                {product[1].item_name}
              </a>
            </h4>
            <p>{product[1].item_description}</p>
          </div>

          <div className="mad-product-calc">
            <span className="mad-product-price">
              {product[1].price.length === 1 && (
                <span>{product[1].price}€</span>
              )}
              {typeof product[1].price.length === 'undefined' &&
                Object.values(product[1].price).map((price, i) => (
                  <span>{price}€</span>
                ))}
            </span>
            <button
              className={`btn btn-style-3 btn-small ${
                disabled === true && preOrderState === false ? 'disabled' : null
              }`}
              onClick={() => {
                if (disabled === true && preOrderState === false) {
                  return;
                } else {
                  setOpenModal(true);
                }
              }}
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalMenu
          product={product}
          sendDataToParent={sendDataToParent}
          index="1"
        />
      )}
    </Fragment>
  );
};

export default Product;
