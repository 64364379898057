import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [mobileNavMenu, setMobileNavMenu] = useState(false);
  return (
    <div>
      <header id="mad-header" className="mad-header ">
        <div className="mad-header-section--sticky-xl">
          <div className="mad-header-items">
            <div className="mad-header-item">
              <Link to="/" className="mad-logo">
                <img src="images/logo.png" alt="" />
              </Link>
            </div>
            <nav className="mad-navigation-container">
              <button
                className="mad-mobile-nav-btn"
                onClick={() => {
                  setMobileNavMenu(!mobileNavMenu);
                }}
              ></button>
              <ul
                className={`mad-navigation mad-navigation--vertical-sm ${
                  mobileNavMenu === true ? "d-m-block" : "d-m-none"
                }`}
              >
                <li className="menu-item">
                  <Link to="/">Accueil</Link>
                </li>
                <li className="menu-item mega-menu">
                  <Link to="/menu">Notre carte</Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact">Nous contacter</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
