import React, { Fragment, useState, useEffect } from "react";
import HomeHero from "./components/HomeHero";
import HowItWorks from "./components/HowItWorks";
import MadSection from "./components/MadSection";
import AppSection from "./components/AppSection";
import FooterBanners from "./components/FooterBanners";
import DiscoverMenu from "./components/DiscoverMenu";
import BlogSection from "./components/BlogSection";
import ImportScript from "./components/ImportScript";
import { Helmet } from "react-helmet";

const Home = () => {
  ImportScript("vendors/modernizr.js");
  ImportScript("vendors/jquery-3.3.1.min.js");
  ImportScript("vendors/revolution/js/jquery.themepunch.tools.min.js");
  ImportScript("vendors/revolution/js/jquery.themepunch.revolution.min.js");
  ImportScript(
    "vendors/revolution/js/extensions/revolution.extension.layeranimation.min.js"
  );
  ImportScript(
    "vendors/revolution/js/extensions/revolution.extension.slideanims.min.js"
  );
  ImportScript("js/mad.app.js");

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sushi Street</title>
        <meta
          name="description"
          content="Sushi Street est le seul restaurant qui vous fera sentir le gout Asiatique (chinois, japonais, ...) sans vous déplacer d'un seul pas en avant."
        />
        <meta
          name="keywords"
          content="sushi, asiatique, livraison, commande en ligne, bobigny, bourget, pantin"
        />
      </Helmet>
      <div id="mad-page-wrapper" className="mad-page-wrapper">
        <HomeHero />
        <div className="mad-content no-pd">
          <div className="container">
            <DiscoverMenu />
            <MadSection />
            <HowItWorks />
            <BlogSection />
          </div>
          <AppSection />
        </div>
        <FooterBanners />
      </div>
    </Fragment>
  );
};

export default Home;
