import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PaypalProvider = ({
  totalPrice,
  orderReadyToSubmit,
  successCallBack,
  paypalMessageErrorCallBack,
  paypalOrderId,
}) => {
  let navigate = useNavigate();
  // creates a paypal order
  const createOrder = (data, actions) => {
    if (paypalOrderId) {
      return actions.order
        .create({
          purchase_units: [
            {
              description: 'Sushi Street',
              amount: {
                currency_code: 'EUR',
                value: parseFloat(totalPrice).toFixed(2),
              },
            },
          ],
          // not needed if a shipping address is actually needed
          application_context: {
            shipping_preference: 'NO_SHIPPING',
          },
        })
        .then((orderId) => {
          let state = false;
          fetch(
            `${process.env.REACT_APP_API_URL}/restaurants/6/orders/${paypalOrderId}/addPayPal`,
            {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ paypal_id: orderId }),
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              state = res.state;

              if (!res.state) {
                toast.warn('There was an error with your order');
              }
            })
            .catch((err) => {
              console.log(err);
            });

          return orderId;
        });
    } else {
      return;
    }
  };
  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer, id } = details;
      successCallBack(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/restaurants/checkPaypalPayment/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
        .then(() => {
          navigate(
            `/validate-pay-pal-payment?paypal_id=${id}&order_id=${paypalOrderId}`
          );
        })
        .catch(() => {
          navigate(
            `/validate-pay-pal-payment?paypal_id=${id}&order_id=${paypalOrderId}`
          );
        });
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    paypalMessageErrorCallBack('An Error occured with your payment ');
  };
  // paypal checkout end
  return (
    <PayPalScriptProvider
      options={{
        'client-id':
          'ASR5UDCNpES0zhGAxm6cPVtpAlT0DbCdoc94eOJpRnubrUBnatawwkBUSer666z4ZM_4sKFVsDlHf-QC',
        locale: 'fr_FR',
        currency: 'EUR',
      }}
    >
      <div className="form-control payment-option">
        {orderReadyToSubmit === true ? (
          <PayPalButtons
            style={{ layout: 'vertical' }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource="paypal"
          />
        ) : (
          <PayPalButtons
            disabled
            style={{ layout: 'vertical' }}
            createOrder={createOrder}
            onApprove={onApprove}
            fundingSource="paypal"
          />
        )}
      </div>
    </PayPalScriptProvider>
  );
};

export default PaypalProvider;
