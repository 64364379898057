import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalMenu from "./ModalMenu";

const HomeHero = () => {
  const [mobileNavMenu, setMobileNavMenu] = useState(false);
  const [firstProduct, setFirstProduct] = useState("");
  const [secondProduct, setSecondProduct] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(0);

  const sendDataToParent = (st) => {
    setOpenModal(st);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/restaurants/6/items/206`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      })
      .then((data) => {
        setFirstProduct(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/restaurants/6/items/204`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      })
      .then((data) => {
        setSecondProduct(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <header id="mad-header" className="mad-header mad-header--transparent">
        <div className="mad-header-section--sticky-xl">
          <div className="mad-header-items">
            <div className="mad-header-item">
              <Link to="/" className="mad-logo">
                <img src="images/logo.png" alt="" />
              </Link>
            </div>

            <nav className="mad-navigation-container">
              <button
                className="mad-mobile-nav-btn"
                onClick={() => {
                  setMobileNavMenu(!mobileNavMenu);
                }}
              ></button>
              <ul
                className={`mad-navigation mad-navigation--vertical-sm ${
                  mobileNavMenu === true ? "d-m-block" : "d-m-none"
                }`}
              >
                <li className="menu-item">
                  <Link to="/">Accueil</Link>
                </li>

                <li className="menu-item mega-menu">
                  <Link to="/menu">Notre carte</Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact">Nous contacter</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <div
        id="rev_slider_1_1_wrapper"
        className="rev_slider_wrapper fullwidthbanner-container"
        data-alias="slider1"
        data-source="gallery"
      >
        <div
          id="rev_slider_1_1"
          className="rev_slider fullwidthabanner d-none"
          data-version="5.4.8.1"
        >
          <ul>
            <li
              data-index="rs-1"
              data-transition="fadetoleftfadefromright,fadefromright,slotslide-horizontal"
              data-slotamount="default,default,default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default,default,default"
              data-easeout="default,default,default"
              data-masterspeed="default,default,default"
              data-rotate="0,0,0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="vendors/revolution/assets/6cbea-bg1.jpg"
                alt=""
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption tp-resizeme z-index-5"
                id="slide-1-layer-5"
                data-x="800"
                data-y="172"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":470,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+7030","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/71681-small5.png"
                  alt=""
                  data-ww="1039px"
                  data-hh="770px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-6"
                id="slide-1-layer-6"
                data-x="858"
                data-y="190"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":710,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6790","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/40800-small4.png"
                  alt=""
                  data-ww="977px"
                  data-hh="889px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-7"
                id="slide-1-layer-7"
                data-x="875"
                data-y="256"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":970,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6530","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/d0628-small3.png"
                  alt=""
                  data-ww="1045px"
                  data-hh="556px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-8"
                id="slide-1-layer-9"
                data-x="817"
                data-y="154"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1350,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6150","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/bc959-small1.png"
                  alt=""
                  data-ww="1040px"
                  data-hh="867px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-9"
                id="slide-1-layer-10"
                data-x="924"
                data-y="193"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1770,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5730","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/018e7-small4.png"
                  alt=""
                  data-ww="922px"
                  data-hh="861px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-11"
                id="slide-1-layer-12"
                data-x="951"
                data-y="271"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":310,"speed":1500,"frame":"0","from":"y:bottom;rZ:90deg;sX:2;sY:2;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+7190","speed":2000,"frame":"999","to":"x:right;rZ:90deg;","ease":"Power4.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-rotate"
                  data-easing="Linear.easeNone"
                  data-startdeg="0"
                  data-enddeg="20"
                  data-speed="19"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/1eb76-big2.png"
                    alt=""
                    data-ww="786px"
                    data-hh="776px"
                    data-no-retina
                  />{" "}
                </div>
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-12"
                id="slide-1-layer-13"
                data-x="1669"
                data-y="780"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1090,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6410","speed":2000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-pendulum"
                  data-easing=""
                  data-startdeg="-20"
                  data-enddeg="-8"
                  data-speed="2"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/96fdd-big6.png"
                    alt=""
                    data-ww="204px"
                    data-hh="324px"
                    data-no-retina
                  />{" "}
                </div>
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-13"
                id="slide-1-layer-14"
                data-x="858"
                data-y="792"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1310,"speed":2000,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5690","speed":2000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-pendulum"
                  data-easing=""
                  data-startdeg="-20"
                  data-enddeg="-14"
                  data-speed="2"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/09e42-big5.png"
                    alt=""
                    data-ww="302px"
                    data-hh="315px"
                    data-no-retina
                  />{" "}
                </div>
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-14"
                id="slide-1-layer-29"
                data-x="1646"
                data-y="220"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1570,"speed":1200,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6230","speed":2000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-pendulum"
                  data-easing=""
                  data-startdeg="-20"
                  data-enddeg="-15"
                  data-speed="2"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/d70da-big4.png"
                    alt=""
                    data-ww="220px"
                    data-hh="377px"
                    data-no-retina
                  />{" "}
                </div>
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-15"
                id="slide-1-layer-16"
                data-x="849"
                data-y="226"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1550,"speed":2000,"frame":"0","from":"x:right;rX:0deg;rY:0deg;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5450","speed":2000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-pendulum"
                  data-easing=""
                  data-startdeg="-20"
                  data-enddeg="-10"
                  data-speed="2"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/f13af-big3.png"
                    alt=""
                    data-ww="288px"
                    data-hh="397px"
                    data-no-retina
                  />{" "}
                </div>
              </div>

              <div
                className="tp-caption rev-btn z-c-16"
                id="slide-1-layer-27"
                data-x="left"
                data-y="top"
                data-hoffset="['255', '255', '255', '255']"
                data-voffset="['816', '816', '816', '816']"
                data-width="['auto']"
                data-height="['auto']"
                data-type="button"
                data-responsive_offset="on"
                data-frames='[{"delay":2510,"speed":1200,"frame":"0","from":"z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"+5290","speed":1200,"frame":"999","to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(184,0,31);bw:0px 0px 0px 0;"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[35,35,35,35]"
                data-paddingright="[115,115,115,115]"
                data-paddingbottom="[29,29,29,29]"
                data-paddingleft="[115,115,115,115]"
                onClick={() => {
                  setSelectedProduct(1);
                  setOpenModal(true);
                }}
              >
                JE COMMANDE !{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c-17"
                id="slide-1-layer-26"
                data-x="left"
                data-y="top"
                data-hoffset="['283', '283', '283', '283']"
                data-voffset="['911', '911', '911', '911']"
                data-width="['auto']"
                data-height="['auto']"
                data-visibility="['on','on','on','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":3130,"speed":1200,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+4670","speed":800,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                1 menu street au choix + boisson + salade ou dessert
              </div>

              <div
                className="tp-caption tp-resizeme z-c-18"
                id="slide-1-layer-30"
                data-x="left"
                data-y="top"
                data-width="['468']"
                data-hoffset="['246', '246', '246', '246']"
                data-voffset="['262', '262', '262', '262']"
                data-height="['323']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":830,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+6170","split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                Formule{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c-19"
                id="slide-1-layer-31"
                data-x="left"
                data-y="top"
                data-hoffset="['244', '244', '244', '244']"
                data-voffset="['382', '382', '382', '382']"
                data-width="['auto']"
                data-height="['auto']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":1190,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+5910","split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                Malin{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c-20"
                id="slide-1-layer-32"
                data-x="left"
                data-y="top"
                data-hoffset="['257', '257', '257', '257']"
                data-voffset="['530', '530', '530', '530']"
                data-width="['auto']"
                data-height="['auto']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power2.easeInOut"},{"delay":"+5990","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                Notre menu complet
              </div>

              <div
                className="tp-caption   tp-resizeme z-c-21"
                id="slide-1-layer-33"
                data-x="left"
                data-y="top"
                data-hoffset="['252', '252', '252', '252']"
                data-voffset="['683', '683', '683', '683']"
                data-width="['46']"
                data-height="['81']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":1810,"speed":1000,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+6190","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              ></div>

              <div
                className="tp-caption   tp-resizeme z-c-26"
                id="slide-1-layer-38"
                data-x="left"
                data-y="top"
                data-hoffset="['523', '523', '523', '523']"
                data-voffset="['652', '652', '652', '652']"
                data-width="['97']"
                data-height="['111']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":2670,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+4730","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                14{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c-27"
                id="slide-1-layer-39"
                data-x="left"
                data-y="top"
                data-hoffset="['615', '615', '615', '615']"
                data-voffset="['648', '648', '648', '648']"
                data-width="['90']"
                data-height="['71']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":2790,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+4510","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                .90{" "}
              </div>
              <div
                className="tp-caption   tp-resizeme z-c-25"
                id="slide-1-layer-37"
                data-x="left"
                data-y="top"
                data-hoffset="['699', '689', '699', '689']"
                data-voffset="['666', '666', '666', '666']"
                data-width="['46']"
                data-height="['81']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":2530,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+4970","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                €{" "}
              </div>
            </li>
            <li
              data-index="rs-5"
              data-transition="fadetoleftfadefromright,fadefromright,slotslide-horizontal"
              data-slotamount="default,default,default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default,default,default"
              data-easeout="default,default,default"
              data-masterspeed="default,default,default"
              data-rotate="0,0,0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="vendors/revolution/assets/6cbea-bg1.jpg"
                alt=""
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-no-retina
              />

              <div
                className="tp-caption   tp-resizeme z-index-5"
                id="slide-5-layer-5"
                data-x="800"
                data-y="172"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":470,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+7030","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/71681-small5.png"
                  alt=""
                  data-ww="1039px"
                  data-hh="770px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-6"
                id="slide-5-layer-6"
                data-x="858"
                data-y="250"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":710,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6790","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/40800-small4.png"
                  alt=""
                  data-ww="977px"
                  data-hh="889px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-7"
                id="slide-5-layer-7"
                data-x="875"
                data-y="256"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":970,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6530","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/d0628-small3.png"
                  alt=""
                  data-ww="1045px"
                  data-hh="556px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-8"
                id="slide-5-layer-9"
                data-x="807"
                data-y="234"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1350,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+6150","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/bc959-small1.png"
                  alt=""
                  data-ww="1040px"
                  data-hh="867px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-9"
                id="slide-5-layer-10"
                data-x="874"
                data-y="303"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1770,"speed":1500,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5730","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <img
                  src="vendors/revolution/assets/018e7-small4.png"
                  alt=""
                  data-ww="922px"
                  data-hh="861px"
                  data-no-retina
                />{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-10"
                id="slide-5-layer-12"
                data-x="811"
                data-y="231"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":310,"speed":1500,"frame":"0","from":"y:bottom;rZ:90deg;sX:2;sY:2;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+7190","speed":2000,"frame":"999","to":"x:right;rZ:90deg;","ease":"Power4.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-rotate"
                  data-easing="Linear.easeNone"
                  data-startdeg="0"
                  data-enddeg="20"
                  data-speed="19"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/5b6b6-burger.png"
                    alt=""
                    data-ww="989px"
                    data-hh="804px"
                    data-no-retina
                  />{" "}
                </div>
              </div>

              <div
                className="tp-caption   tp-resizeme z-index-11"
                id="slide-5-layer-14"
                data-x="858"
                data-y="792"
                data-width="['none','none','none','none']"
                data-height="['none','none','none','none']"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":1310,"speed":2000,"frame":"0","from":"x:right;rZ:90deg;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5690","speed":2000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                <div
                  className="rs-looped rs-pendulum"
                  data-easing=""
                  data-startdeg="-20"
                  data-enddeg="-14"
                  data-speed="2"
                  data-origin="50% 50%"
                >
                  <img
                    src="vendors/revolution/assets/872c3-banka.png"
                    alt=""
                    data-ww="277"
                    data-hh="442"
                    data-no-retina
                  />
                </div>
              </div>

              <div
                className="tp-caption rev-btn z-c-12"
                id="slide-5-layer-27"
                data-x="255"
                data-y="816"
                data-width="['auto']"
                data-height="['auto']"
                data-type="button"
                data-responsive_offset="on"
                data-frames='[{"delay":2510,"speed":1200,"frame":"0","from":"z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeOut"},{"delay":"+5290","speed":1200,"frame":"999","to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgb(255,255,255);bg:rgb(184,0,31);bw:0px 0px 0px 0;"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[35,35,35,35]"
                data-paddingright="[115,115,115,115]"
                data-paddingbottom="[29,29,29,29]"
                data-paddingleft="[115,115,115,115]"
                onClick={() => {
                  setSelectedProduct(2);
                  setOpenModal(true);
                }}
              >
                JE COMMANDE !{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c-13 text-center"
                id="slide-5-layer-26"
                data-x="283"
                data-y="911"
                data-width="['auto']"
                data-height="['auto']"
                data-visibility="['on','on','on','off']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":3130,"speed":1200,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+4670","speed":800,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textalign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                4 sushi saumon, 4 sushi thon, 6 maki saumon <br />1
                accompagnement offert + 2 sauces au choix
              </div>

              <div
                className="tp-caption z-c-14  tp-resizeme"
                id="slide-5-layer-30"
                data-x="253"
                data-y="339"
                data-width="['469']"
                data-height="['auto']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":830,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+6070","split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                SPECIAL
              </div>

              <div
                className="tp-caption 200px   tp-resizeme z-c2-15"
                id="slide-5-layer-31"
                data-x="252"
                data-y="450"
                data-width="['auto']"
                data-height="['auto']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":1190,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+5510","split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                MENU STREET 6{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c2-16"
                id="slide-5-layer-32"
                data-x="255"
                data-y="530"
                data-width="['auto']"
                data-height="['auto']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power2.easeInOut"},{"delay":"+5990","speed":1500,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                Accompagnement Offert{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c2-21"
                id="slide-5-layer-37"
                data-x="730"
                data-y="666"
                data-width="['46']"
                data-height="['81']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":2530,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+4970","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                €{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c2-22"
                id="slide-5-layer-38"
                data-x="535"
                data-y="652"
                data-width="['97']"
                data-height="['111']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":2670,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+4730","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                11{" "}
              </div>

              <div
                className="tp-caption   tp-resizeme z-c2-23 "
                id="slide-5-layer-39"
                data-x="645"
                data-y="648"
                data-width="['90']"
                data-height="['71']"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":2790,"split":"chars","splitdelay":0.1000000000000000055511151231257827021181583404541015625,"speed":1500,"split_direction":"forward","frame":"0","from":"x:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;skY:-5px;","ease":"Power3.easeInOut"},{"delay":"+4510","speed":1000,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                data-textalign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
              >
                .90{" "}
              </div>
            </li>
          </ul>
          <div className="tp-bannertimer tp-bottom visibility-hidden"></div>
        </div>
      </div>
      <div className="container">
        <div className="mad-menu-box with-slide-top d-sm-none">
          <div className="mad-col">
            <a href="#" className="mad-menu-item">
              <i className="mad-menu-icon">
                <img
                  className="svg"
                  src="yummi_svg_icons/sushi.png"
                  alt=""
                  loading="lazy"
                />
              </i>
              <h6 className="mad-menu-title">Japonais</h6>
            </a>
          </div>
          <div className="mad-col">
            <a href="#" className="mad-menu-item">
              <i className="mad-menu-icon">
                <img
                  className="svg"
                  src="yummi_svg_icons/chinese.png"
                  alt=""
                  loading="lazy"
                />
              </i>
              <h6 className="mad-menu-title">Chinois</h6>
            </a>
          </div>
          <div className="mad-col">
            <a href="#" className="mad-menu-item">
              <i className="mad-menu-icon">
                <img
                  className="svg"
                  src="yummi_svg_icons/menus.png"
                  alt=""
                  loading="lazy"
                />
              </i>
              <h6 className="mad-menu-title">Menus</h6>
            </a>
          </div>
          <div className="mad-col">
            <a href="#" className="mad-menu-item">
              <i className="mad-menu-icon">
                <img
                  className="svg"
                  src="yummi_svg_icons/accompagnements.png"
                  alt=""
                  loading="lazy"
                />
              </i>
              <h6 className="mad-menu-title">
                Accompag-
                <br />
                nements
              </h6>
            </a>
          </div>
          <div className="mad-col">
            <a href="#" className="mad-menu-item">
              <i className="mad-menu-icon">
                <img
                  className="svg"
                  src="yummi_svg_icons/boissons.png"
                  alt=""
                  loading="lazy"
                />
              </i>
              <h6 className="mad-menu-title">Boissons</h6>
            </a>
          </div>
          <div className="mad-col">
            <a href="#" className="mad-menu-item">
              <i className="mad-menu-icon">
                <img
                  className="svg"
                  src="yummi_svg_icons/desserts.png"
                  alt=""
                  loading="lazy"
                />
              </i>
              <h6 className="mad-menu-title">Desserts</h6>
            </a>
          </div>
        </div>
      </div>
      {selectedProduct === 1 && openModal ? (
        <ModalMenu
          product={firstProduct}
          sendDataToParent={sendDataToParent}
          index="0"
        />
      ) : (
        <div></div>
      )}

      {selectedProduct === 2 && openModal ? (
        <ModalMenu
          product={secondProduct}
          sendDataToParent={sendDataToParent}
          index="0"
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HomeHero;
