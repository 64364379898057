import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "./screens/Menu";
import Contact from "./screens/Contact";
import Checkout from "./screens/Checkout";
import NotFound from "./screens/NotFound";
import allReducers from "./reducers/index";
import { createStore } from "redux";
import { Provider } from "react-redux";
import Footer from "./screens/components/Footer";
import ValidatePayPlugPayment from "./screens/ValidatePayPlugPayment";
import ValidatePayPalPayment from "./screens/ValidatePayPalPayment";
import DownloadApp from "./screens/components/DownloadApp";
import Login from "./screens/Login";
import ThankYou from "./screens/ThankYou";

const store = createStore(allReducers);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter basename="/">
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DownloadApp />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/menu" exact element={<Menu />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/checkout" exact element={<Checkout />} />
          <Route
            path="/validate-pay-plug-payment"
            exact
            element={<ValidatePayPlugPayment />}
          />
          <Route
            path="/validate-pay-pal-payment"
            exact
            element={<ValidatePayPalPayment />}
          />
          <Route path="/login" exact element={<Login />} />
          <Route path="/thank-you" exact element={<ThankYou />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
